.heroGames{
    margin-top: 40px;
    margin-bottom: 100px;
    .game-tile{
        .game-tile-badge{
            bottom: 0 !important;
            z-index: 999;
            box-shadow: none !important;
            background-color: rgb(42 123 254);
            color: #ffffff;
            width: auto;
            right: unset !important;
            padding: 10px !important;
            border-radius: 0px 10px;
            max-width: 100%;
            outline: none !important;
            border: none !important;
            left: 0 !important;
        }
        .game-tile-category{
            top: unset !important;
            bottom: 45px;
            font-size: 12px;
            border-radius: 5px;
            transition: .3s;
        }
        &:hover{
            box-shadow: none !important;
            .game-tile-content{
                outline: none !important;
            }
            .game-tile-category{
                bottom: 50px;
            }
        }
    }
}
.heroGames-tile20{
    width: 20%;
}
.heroGames-tile60{
    width: 60%;
}
.heroGames-tile40{
    width: 40%;
}
.game-large{
    .game-tile-content{
    height: 300px;
}
}
.game-small{
    .game-tile-content{
    height: 150px;
}
}
@media(max-width:767px){
    .heroGames{
        margin-top: 20px;
        margin-bottom: 80px;
    }
    .game-large{
        .game-tile-content{
        height: 150px;
    }
    }
    .game-small{
        .game-tile-content{
        height: 150px;
    }
    }
    .heroGames-tile20{
        width: 50%;
    }
    .heroGames-tile60{
        width: 100%;
    }
    .heroGames-tile40{
        width: 100%;
    }
    .mobile-33{
        width: 33.33% !important;
    }
    .mobile-33{
        .game-tile-content{
            height: 100px;
        }
    }
}