.button-action {
    border: 5px solid #FD7A4829;
    border-radius: 50px;
    color: #ffffff;
    padding: 10px 30px;
    font-size: 13px;

    img {
        margin-right: 10px;
        height: 24px;
    }

    i {
        margin-right: 15px;
    }
}