@import "theme/custom";

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s ease-in-out infinite;
  -moz-animation: rotating 1s ease-in-out infinite;
  -ms-animation: rotating 1s ease-in-out infinite;
  -o-animation: rotating 1s ease-in-out infinite;
  animation: rotating 1s ease-in-out infinite;
}
.rotating-icon:before {
  -webkit-animation: rotating 1s ease-in-out infinite;
  -moz-animation: rotating 1s ease-in-out infinite;
  -ms-animation: rotating 1s ease-in-out infinite;
  -o-animation: rotating 1s ease-in-out infinite;
  animation: rotating 1s ease-in-out infinite;
}