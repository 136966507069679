.button-image {
    border-radius: 50%;
    outline: 7px solid #ffffff !important;
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;

    img {
        width: 100%;
        max-width: 26px;
        height: auto;
    }
    &:hover{
        box-shadow: 0 0 10px 0 rgba(255,255,255,1);
    }
}