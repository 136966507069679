@media(max-width:767px){
    .text-header{
        padding: 0px !important;
        margin-bottom: 40px;
        font-size: 36px !important;
        &::before{
            left: -40px !important;
        }
    }
}
.text-header {
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: 800;
    padding: 50px 0 50px 35px;
    position: relative;
    z-index: 1;

    &.white {
        color: #FFFFFF;
    }

    &.color {
        color: #050505;

        span {
            color: #FD7A48;
        }
    }

    span {
        font-weight: 800;
    }

    &::before {
        background: #ffffff;
        border-radius: 50%;
        content: '';
        display: block;
        height: 150px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 150px;
        z-index: -1;
    }

    &.white::before {
        opacity: 0.3;
    }
}
.header-gamePage{
    font-size: 36px;
    font-weight: 800;
}