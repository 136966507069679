.search-form{
    position: relative;
    flex: 1;
    .search-results{
        position: absolute;
        top: 0;
        height: auto;
        background: linear-gradient(#25d6ff, #2a7bfe);
        overflow: scroll;
        z-index: 999;
        padding: 80px 0px 0px;
        border-radius: 30px 30px 20px 20px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
        width: calc(100% + 6px);
        .search-games-wrap{
            padding: 0 20px;
        }
        .more-games-wrap{
            padding: 15px;
            background: #FFE600;
            justify-content: center;
            button{
                width: auto;
                color: rgb(253, 122, 72);
            }
        }
        .search-gameTile{
            position: relative;
            margin-bottom: 20px;
            .search-gameImg{
                border-radius: 10px;
            }
            .search-gameName{
                visibility: hidden;
                opacity: 0;
                position: absolute;
                left: 0;
                bottom: -10px;
                white-space: nowrap;
                background: #ffffff;
                padding: 3px;
                font-size: 13px;
                font-family: 'Poppins';
                transition: .3s;
                overflow: hidden;
                text-overflow: ellipsis;
                width: calc(100% - 20px);
                border-radius: 5px;
                margin: 0 auto;
                right: 0;
            }
            &:hover{
                .search-gameName{
                    visibility: visible;
                    opacity: 1;
                    bottom: 0;
                }
            }
        }
        &[data-visibility="hidden"]{
            display: none;
        }
        &[data-visibility="visible"]{
            display: block;
        }
    }
    input[type="search"]{
        width: 100%;
        z-index: 1000;
    }
    button{
        z-index: 9999 !important;
    }
}