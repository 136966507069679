.button-rounded {
    border: none;
    border-radius: 10px;
    color: #ffffff;
    padding: 10px;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    outline: 0px solid #ffffff;
    transition: .3s;
    img {
        margin-right: 10px;
        height: 24px;
        width: 24px;
        object-fit: contain;
        transform: scale(1);
        transition: .3s;
    }
    &:hover{
        background: #AC2D4D !important;
        color: #fff !important;
        img{
            transform: scale(1.15);
        }
        outline-width: 3px;
    }
}