@media(max-width:767px){
    .footer-text-block .footer-text-block-img{
        display: none;
    }
}
.footer-text-block {
    position: relative;
    margin-bottom: 71px;
    .footer-text-block-content {
        color: #050505;
        font: normal normal normal 15px/23px 'Poppins';
        letter-spacing: 0px;
    }

    &.white {
        .footer-text-block-content {
            color: #FFFFFF;
        }
    }

    .footer-text-block-img {
        position: relative;

        img {
            bottom: 0;
            max-width: 100%;
            width: 100%;
        }
    }
}