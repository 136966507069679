@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
    background: #F3F6FB;

    a {
        text-decoration: none;
    }

    .iframe-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.top-row--icons{
    gap: 30px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 7px;
    .nav-link{
        padding: 0;
        &:hover{
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
        }
    }
}
.search-form{
    position: relative;
    align-items: center;
    .form-field--input{
        z-index: 1;
        padding: 18px;
        border-radius: 30px;
        border: none;
        transition: .3s;
    }
    input:focus{
        outline: none;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    }
    input::placeholder{
        color: #FFCCB9;
    }
    input::ms-input-placeholder{
        color: #FFCCB9;
    }
    .button-image{
        position: absolute;
        right: 0;
        z-index: 2;
        box-shadow: 0 0 10px 0 rgb(0,0,0)
        }
}
.modal-heading{
    font-size: 24px;
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
}
.modal-body{
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
}
.button-menu_switcher{
    border-radius: 50%;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    outline: 5px solid #ffffff;
}
.button-menu_switcher i{
    font-size: 21px;
}
.modal-header, .modal-content{
    border: none !important;
}
.mobile-menu-modal{
    .btn-close{
        background: #2A7CFE;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        outline: 5px solid #ffffff;
    }
    .modal-dialog{
        width: calc(100vw - 80px);
        margin: 0 0 0 80px;
    }
    .modal-content{
        height: 100vh;
        background-color: #4090FF;
        a{
            margin-bottom: 10px;
            button{
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
:focus, :active{
    outline: none !important;
}
.errorPage--img{
    width: 100%;
    max-width: 300px;
}
.errorPage--heading{
    font-family: 'Poppins', sans-serif;
}
.topNavbar--link{
    button{
        height: 54px;
    }
}
.topNavbar--home{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9b1f3e;
    width: 54px;
    height: 54px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 21px;
    i{
        transform: scale(1);
        transition: .3s;
    }
    &:hover{
        color: #ffffff;
        i{
            transform: scale(1.15);
        }
    }
}
@media(max-width:767px){
    .top-row--icons_wrap{
        display: flex;
        justify-content: flex-end;
    }
}