.game-iframe {
    .not-loaded-box {
        background-color: #FFFFFF;
        background-size: cover;
        background-position: center;
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        border-radius: 30px;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #77777766;
            border-radius: 30px;

            i {
                cursor: pointer;
                color: #FD7A48;
                font-size: 80px;
            }

            .bi-play-circle:hover {
                transform: scale(1.1);
                transition: 0.3s;
            }
        }
    }

    .mobile-iframe {
        position: fixed;
        z-index: 9999;
        padding: 0;
        margin: 0;
    }

    // add styles for close button in top right corner
    .close-button {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 99999;
        background-color: #FD7A48;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background-color: #FF8F6B;
        }
        i {
            color: #ffffff;
            font-size: 20px;
        }
    }
}