.game-tile {
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;

    .game-tile-content {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        display: block;
        text-align: center;
        height: 150px;
        position: relative;
        z-index: 10;
        overflow: hidden;
        transition: outline-width .3s;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            &:hover {
                transform: scale(1.1);
                transition: all .2s;
            }
        }

        .game-tile-category {
            border-radius: 0px 10px 10px 0px;
            padding: 2px 8px;
            font-size: 10px;
            color: #ffffff;
            position: absolute;
            left: 0;
            top: 14px;
            z-index: 20;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
        }
    }

    .game-tile-badge {
        background: linear-gradient(rgb(37, 214, 255), rgb(42, 123, 254));
        color: #ffffff;
        border-radius: 0px 0px 10px 10px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        padding: 24px 10px 11px 10px;
        z-index: 5;
        transition: bottom .2s;
        left: 0;
        .game-tile-name{
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 800;
        }
    }

    &:hover {
        box-shadow: 0 0 20px 2px rgba(37,214,255,0.8);
        .game-tile-content {
            z-index: 30;
            outline-color: #fff;
            outline-style: solid;
            outline-width: 6px;
        }

        .game-tile-badge {
            bottom: -50px;
            z-index: 20;
        border: 6px solid #ffffff;
        width: calc(100% + 12px);
        left: -6px;
        }
    }
}

@media (max-width:767px){
    .game-tile .game-tile-content{
        height: 100px;
    }
    .game-tile-badge{
        display: none !important;
    }
}