.games-block {
    margin-bottom: 30px;
    .game-tile-category{
        display: none;
    }
}
.moreGames-wrap{
    padding: 0 20px 20px;
}
.moreGames{
    background-color: #FD7A48;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 10px;
    .moreGames-title{
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
    }
    .moreGames-link{
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        font-weight: 400;
    }
}