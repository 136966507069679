.error-game {
    .not-loaded-box {
        background-color: #FFFFFF;
        background-size: cover;
        background-position: center;
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        border-radius: 30px;

        .icon {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #77777766;
            border-radius: 30px;

            i {
                cursor: pointer;
                color: #FD7A48;
                font-size: 80px;
            }

            .bi-play-circle:hover {
                transform: scale(1.1);
                transition: 0.3s;
            }
        }
    }
}