.category-tile {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000000D;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    transform: scale(1);
    transition: .3s;
    &:hover{
        background: #AC2D4D !important;
        transform: scale(1.03);
        span{
            color: #ffffff !important;
        }
        img{
            transform: scale(1.15);
        }
    }

    img {
        width: 36px;
        margin-right: 20px;
        border-radius: 5px;
        transform: scale(1);
    }

    span {
        letter-spacing: 0px;
        color: #050505;
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.large {
        flex-direction: column;
        height: auto;
        padding: 0;
        overflow: hidden;
        border: none;

        img {
            width: 100%;
            height: 110px;
            object-fit: cover;
        }

        span {
            text-align: center;
            width: 100%;
            padding: 7px;
        }
    }
}
@media(max-width: 1024px){
    .category-tile span{
        font-size: 13px !important;
    }
    .category-tile img{
        margin-right: 10px !important;
    }
}