.footer-navbar {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 30px;
}
.footer-navbar .nav-link{
    font-family: 'Poppins', sans-serif;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
    font-weight: 500;
}